import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Form, Modal, Alert } from 'react-bootstrap';
import api from '../api';
import styles from './ManageUsers.module.css';


const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    username: '',
    email: '',
    address: '',
    password: ''
  });
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/users', { withCredentials: true });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setErrorMessage('Error fetching users.');
      }
    };

    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
        console.log('CSRF token fetched:', response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        setErrorMessage('Error fetching CSRF token.');
      }
    };

    fetchUsers();
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleDelete = async (id) => {
    try {
      console.log(`Deleting user with id: ${id}`);
      await api.delete(`/users/${id}`, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
      setUsers(users.filter(user => user.id !== id));
      console.log(`User with id: ${id} deleted successfully.`);
      setSuccessMessage('User deleted successfully.');
    } catch (error) {
      console.error('Error deleting user:', error);
      setErrorMessage('Error deleting user.');
    }
  };

  const handleShow = (user = {}) => {
    console.log('Show modal for user:', user);
    setFormData({
      id: user.id || '',
      username: user.username || '',
      email: user.email || '',
      address: user.address || '',
      password: ''
    });
    setErrorMessage('');
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form data:', formData);
    try {
      if (formData.id) {
        console.log('Updating user:', formData);
        await api.put(`/users/${formData.id}`, {
          email: formData.email,
          address: formData.address,
          password: formData.password
        }, {
          headers: {
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true,
        });
        console.log(`User with id: ${formData.id} updated successfully.`);
        setSuccessMessage('User updated successfully.');
      } else {
        console.log('Creating new user:', formData);
        await api.post('/users/register', formData, {
          headers: {
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true,
        });
        console.log('New user created successfully.');
        setSuccessMessage('New user created successfully.');
      }
      setShow(false);
      const response = await api.get('/users', { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      console.error('Error saving user:', error);
      if (error.response?.status === 401) {
        setErrorMessage('Unauthorized error: Please login again.');
      } else {
        setErrorMessage(error.response?.data?.message || 'Došlo je do greške prilikom spremanja korisnika.');
      }
    }
  };

  return (
    <Container>
      <h2 className="mt-4">Manage Users</h2>
      <Button className="mb-3" onClick={() => handleShow()}>Add User</Button>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Table striped bordered hover className={`mt-4 ${styles.tableContainer}`}>
  <thead>
    <tr>
      <th>ID</th>
      <th>Username</th>
      <th>Email</th>
      <th>Address</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {users.map(user => (
      <tr key={user.id} className={styles.tableRow}>
        <td data-label="ID" className={styles.tableCell}>{user.id}</td>
        <td data-label="Username" className={styles.tableCell}>{user.username}</td>
        <td data-label="Email" className={styles.tableCell}>{user.email}</td>
        <td data-label="Address" className={styles.tableCell}>{user.address}</td>
        <td data-label="Actions" className={styles.tableCell}>
          <Button variant="warning" onClick={() => handleShow(user)}>Edit</Button>{' '}
          <Button variant="danger" onClick={() => handleDelete(user.id)}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>



      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{formData.id ? 'Edit User' : 'Add User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Prikaz poruke o grešci */}
          <Form onSubmit={handleSubmit}>
          <Form.Group controlId="username" className={styles.formGroup}>
  <Form.Label className={styles.formLabel}>Username</Form.Label>
  <Form.Control
    type="text"
    name="username"
    className={styles.formControl}
    value={formData.username}
    onChange={handleChange}
    disabled={!!formData.id}
    required={!formData.id}
  />
</Form.Group>
<Form.Group controlId="email" className={styles.formGroup}>
  <Form.Label className={styles.formLabel}>Email</Form.Label>
  <Form.Control
    type="email"
    name="email"
    className={styles.formControl}
    value={formData.email}
    onChange={handleChange}
    required
  />
</Form.Group>
<Form.Group controlId="address" className={styles.formGroup}>
  <Form.Label className={styles.formLabel}>Address</Form.Label>
  <Form.Control
    type="text"
    name="address"
    className={styles.formControl}
    value={formData.address}
    onChange={handleChange}
  />
</Form.Group>
<Form.Group controlId="password" className={styles.formGroup}>
  <Form.Label className={styles.formLabel}>Password</Form.Label>
  <Form.Control
    type="password"
    name="password"
    className={styles.formControl}
    value={formData.password}
    onChange={handleChange}
    required={!formData.id}
  />
</Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {formData.id ? 'Save Changes' : 'Add User'}
            </Button>
          </Form>

        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ManageUsers;
